export default {
	prefix: 'isd',
	iconName: 'akkordeon-minus',
	niceName: 'akkordeon-minus',
	icon: [
		58,
		58,
		[],
		'ff02',
		'M28.62.859c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.859 28.62.859zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.017-14.303-11.607-25.893-25.91-25.91zm14.81 24.66a1.25 1.25 0 010 2.5H13.81a1.25 1.25 0 010-2.5h29.62z'
	]
};