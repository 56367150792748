export default {
	prefix: 'isd',
	iconName: 'berufsbilder',
	niceName: 'berufsbilder',
	icon: [
		55,
		68,
		[],
		'ff08',
		'M20.026 39.516a1.25 1.25 0 011.45 1.24v3.78c.93.42 5.4 1.91 12.32 0v-3.78a1.25 1.25 0 011.45-1.24l5.6.93 4.08.83c.006.001.117.021.31.068l.16.04c1.987.508 9.577 3.035 9.56 11.892v13.24a1.25 1.25 0 01-2.5 0v-13.24c0-6.918-5.693-8.96-7.48-9.424l-.177-.044a4.946 4.946 0 00-.343-.072l-2.66-.55v11.41h2c.69 0 1.25.56 1.25 1.25v10.67a1.25 1.25 0 01-2.5 0v-9.42h-2c-.69 0-1.25-.56-1.25-1.25v-13.13l-3-.49v13.5c0 .69-.56 1.25-1.25 1.25h-14.82a1.25 1.25 0 01-1.26-1.26v-13.49l-3 .49v13.13c0 .69-.56 1.25-1.25 1.25h-2v9.42a1.25 1.25 0 01-2.5 0v-10.67c0-.69.56-1.25 1.25-1.25h2v-11.41l-2.62.54c-.37.06-8.06 1.55-8.06 9.55v13.24a1.25 1.25 0 01-2.5 0v-13.25c0-10.14 9.97-12 10.09-12l4-.81zm13.77 7.59a27.23 27.23 0 01-6.69.9 19.07 19.07 0 01-5.63-.78v7.24h12.32zM30.765.186a1.26 1.26 0 011.191 1.19v.84c3.82 1.09 9.4 4.33 9.4 13.95a2.18 2.18 0 00.85 2.06l.65-.25a1.25 1.25 0 011 2.29 31.06 31.06 0 01-3.29 1.19v.84c0 8.61-6.14 16.45-12.89 16.45-6.75 0-12.89-7.84-12.89-16.45v-.84a31.06 31.06 0 01-3.29-1.19h-.01a1.25 1.25 0 111-2.29l.64.3a2.16 2.16 0 00.86-2.06c0-9.67 5.58-12.91 9.4-14v-.84a1.26 1.26 0 011.25-1.19zm-13.519 21.89v.19c0 7.22 5 14 10.39 14 5.39 0 10.39-6.83 10.39-14v.03c0-.07-.01-.13-.01-.19a47.35 47.35 0 01-10.38 1.1 47.35 47.35 0 01-10.39-1.13zm12.17-19.39h-3.54l-.74 13.64a1.23 1.23 0 01-1.32 1.18 1.25 1.25 0 01-1.18-1.31l.6-11.31c-3.35 1.22-6.75 4.2-6.75 11.28a5.35 5.35 0 01-.76 2.96 42.39 42.39 0 0011.91 1.58 42.46 42.46 0 0011.92-1.54 5.42 5.42 0 01-.77-3c0-7.08-3.4-10.06-6.75-11.28l.6 11.31a1.26 1.26 0 01-1.16 1.31h-.07a1.25 1.25 0 01-1.25-1.18l-.74-13.64z'
	]
};