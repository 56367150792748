export default {
	prefix: 'isd',
	iconName: 'fuer-unternehmen',
	niceName: 'fuer-unternehmen',
	icon: [
		70,
		72,
		[],
		'ff19',
		'M18.92.856c.69 0 1.25.56 1.25 1.25v4.66h3.27a1.25 1.25 0 011.25 1.25v4h2.2c.69 0 1.25.56 1.25 1.25v4.84h3.19a1.25 1.25 0 011.29 1.2v49.55h8.89v-17c-3.05-.59-4.77-3.33-4.77-7.66v-10.42a7.8 7.8 0 017.77-7.79h9.46a7.81 7.81 0 017.79 7.79v10.45c0 4.33-1.77 7.07-4.77 7.66v17h3.26a1.25 1.25 0 010 2.5h-59a1.25 1.25 0 010-2.5h28.83v-48.33H7.76v45.15a1.25 1.25 0 01-2.5 0v-46.35c0-.69.56-1.25 1.25-1.25h19.11v-3.56H10.95a1.25 1.25 0 010-2.5h11.24v-2.75H14.4a1.25 1.25 0 010-2.5h3.27v-4.69c0-.69.56-1.25 1.25-1.25zm49.07 68.03a1.25 1.25 0 010 2.5h-3.43a1.25 1.25 0 010-2.5zm-14.04-40.4h-9.46a5.28 5.28 0 00-5.27 5.29v10.47c0 2.75.77 4.43 2.27 5v-13.5a1.25 1.25 0 012.5 0v33.14h4v-17.87a1.25 1.25 0 012.5 0v17.87h4v-33.14a1.25 1.25 0 012.5 0v13.45c1.5-.52 2.27-2.2 2.27-5l-.02-10.42a5.29 5.29 0 00-5.29-5.29zm-27.81 33.24a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm0-7.28a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm0-7.27a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm0-7.28a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm0-7.29a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm0-7.27a1.25 1.25 0 010 2.5H11.7a1.25 1.25 0 010-2.5zm23.11-13.92a6.17 6.17 0 110 12.34 6.17 6.17 0 010-12.34zm0 2.5a3.67 3.67 0 10.08 7.34 3.67 3.67 0 00-.08-7.34z'
	]
};