export default {
	prefix: 'isd',
	iconName: 'mehr',
	niceName: 'mehr',
	icon: [
		62,
		61,
		[],
		'ff36',
		'M59.87 58.097a1.25 1.25 0 010 2.5H1.37a1.25 1.25 0 010-2.5h58.5zm-29.58-43.31c.69 0 1.25.56 1.25 1.25v13.56H45.1a1.25 1.25 0 010 2.5H31.54v13.56a1.25 1.25 0 01-2.5 0v-13.56H15.48a1.25 1.25 0 010-2.5h13.56v-13.56c0-.69.56-1.25 1.25-1.25zM59.87.097a1.25 1.25 0 010 2.5H1.37a1.25 1.25 0 110-2.5h58.5z'
	]
};