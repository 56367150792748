export default {
	prefix: 'isd',
	iconName: 'ueber-uns-two',
	niceName: 'ueber-uns-two',
	icon: [
		61,
		62,
		[],
		'ff4b',
		'M35.466 29.104a7.94 7.94 0 017.94 7.93v9.79c0 4.46-1.76 7.23-4.89 7.82v6.54h-2.5v-22.1h2.5v13c1.11-.39 2.39-1.57 2.39-5.26v-9.79a5.44 5.44 0 00-5.44-5.43h-9.68c-3 0-5.434 2.43-5.44 5.43v9.77c0 3.69 1.28 4.87 2.39 5.26v-13h2.5v22.1h-2.5v-6.52c-3.13-.59-4.89-3.36-4.89-7.82v-9.79a7.94 7.94 0 017.94-7.93zm17.16 0a7.94 7.94 0 017.93 7.93v9.79c0 4.46-1.76 7.23-4.88 7.82v6.54h-2.5v-22.1h2.5v13c1.1-.39 2.38-1.57 2.38-5.26v-9.79a5.44 5.44 0 00-5.43-5.43h-7.18v-2.5zm-36.81 0v2.5h-7.19c-3 0-5.434 2.43-5.44 5.43v9.77c0 3.69 1.27 4.87 2.38 5.26v-13h2.5v22.1h-2.5v-6.52c-3.12-.59-4.88-3.36-4.88-7.82v-9.79a7.94 7.94 0 017.94-7.93h7.19zm14.81-15.2a6.29 6.29 0 110 12.58 6.29 6.29 0 010-12.58zm17.17 0a6.29 6.29 0 110 12.58 6.29 6.29 0 010-12.58zm-36.744.472a6.29 6.29 0 114.828 11.617 6.29 6.29 0 01-4.828-11.617zm19.574 2.048a3.79 3.79 0 100 7.58 3.79 3.79 0 000-7.58zm17.17 0a3.79 3.79 0 100 7.58 3.79 3.79 0 000-7.58zm-34.33-.02v.02a3.79 3.79 0 00-3.79 3.77 3.79 3.79 0 103.79-3.79zM36.811.912a6.29 6.29 0 114.81 11.624A6.29 6.29 0 0136.81.912zM22.046.434a6.29 6.29 0 110 12.58 6.29 6.29 0 010-12.58zm17.16 2.5a3.79 3.79 0 10.02 7.58 3.79 3.79 0 00-.02-7.58zm-17.16 0a3.79 3.79 0 100 7.58 3.79 3.79 0 000-7.58z'
	]
};