export default {
	prefix: 'isd',
	iconName: 'news-liste-links',
	niceName: 'news-liste-links',
	icon: [
		59,
		58,
		[],
		'ff3d',
		'M29.62.069c15.878 0 28.75 12.872 28.75 28.75-.022 15.87-12.88 28.728-28.75 28.75-15.878 0-28.75-12.872-28.75-28.75S13.742.069 29.62.069zm0 2.5c-14.497 0-26.25 11.753-26.25 26.25s11.753 26.25 26.25 26.25 26.25-11.753 26.25-26.25c-.022-14.488-11.762-26.228-26.25-26.25zm5.89 10.34a1.252 1.252 0 010 1.77l-14.13 14.14 14.11 14.1a1.26 1.26 0 01-.87 2.13 1.21 1.21 0 01-.88-.37l-15-15a1.25 1.25 0 010-1.77l15-15a1.252 1.252 0 011.77 0z'
	]
};