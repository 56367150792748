export default {
	prefix: 'isd',
	iconName: 'banken-versicherungen',
	niceName: 'banken-versicherungen',
	icon: [
		75,
		54,
		[],
		'ff09',
		'M28.77 6.308a1.25 1.25 0 010 2.5h-8.93v42.32h9.9v-6.58h-2.31a1.25 1.25 0 110-2.5h20.19a1.25 1.25 0 010 2.5h-2.29v6.58h9.9V8.808H46.3a1.25 1.25 0 110-2.5h10.18c.69 0 1.25.56 1.25 1.25v43.57h3.31v-4.63a1.25 1.25 0 012.5 0v4.63h3.54v-29.28H60.7a1.25 1.25 0 110-2.5h7.67c.69 0 1.25.56 1.25 1.25v30.53h4a1.25 1.25 0 010 2.5h-72a1.25 1.25 0 110-2.5H5.5v-30.53c0-.69.56-1.25 1.25-1.25h7.63a1.25 1.25 0 010 2.5H8v29.28h3.53v-4.63a1.25 1.25 0 012.5 0v4.63h3.34V7.558c0-.69.56-1.25 1.25-1.25zm14.06 38.24H32.24v6.58h4.09v-4.36a1.25 1.25 0 012.5 0v4.36h4v-6.58zm-30.05-9.15c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32-1.61a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm-38.19-8.5c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32 1.28a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm0-7.22a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zM38.12.469a6.86 6.86 0 016.27.619 3 3 0 01-1.24 1.87 4.14 4.14 0 00-2.53-.73 3.83 3.83 0 00-4 3h4c.061.255.091.517.09.78a3 3 0 01-.09.73h-4.25v1.45h3.91c.048.247.071.498.07.75a3.68 3.68 0 01-.07.73h-3.65a3.91 3.91 0 003.9 3 4.3 4.3 0 002.77-.83 2.57 2.57 0 011.25 1.87 6.25 6.25 0 01-4.09 1.21 6.31 6.31 0 01-6.49-5.28h-2.03a4.65 4.65 0 01-.08-.73 4.78 4.78 0 01.08-.75h1.83v-1.45h-1.43a2.61 2.61 0 01-.09-.73 2.8 2.8 0 01.09-.75h1.65A6.86 6.86 0 0138.12.469z'
	]
};