export default {
	prefix: 'isd',
	iconName: 'weiterbildung',
	niceName: 'weiterbildung',
	icon: [
		59,
		64,
		[],
		'ff50',
		'M16.232 4.575a31.39 31.39 0 0133 .89c14.03 10.34 8.66 25.62 6.71 29.99a25.75 25.75 0 01-1.66 3 34.13 34.13 0 00-3.09 6.91 23.16 23.16 0 00-1.2 8.86 66.53 66.53 0 00.92 7.42 1.25 1.25 0 01-1 1.44h-.21a1.26 1.26 0 01-1.23-1 74.87 74.87 0 01-1-7.69 25.82 25.82 0 011.33-9.8 37.11 37.11 0 013.33-7.43 21.68 21.68 0 001.5-2.73c2.29-5.11 6.3-18-5.88-27a28.4 28.4 0 00-30.31-.71l-.11.15c-.35.16-8.75 4.02-6.88 16.23 0 .48.4 4.32-1 6.38l-.08.11-6.23 7.32a.83.83 0 000 .4c.04.06.22.2.66.2h.15l4.49.92.27.53a21.41 21.41 0 011.8 5.13l4.29.07a1.25 1.25 0 110 2.5l-4.09-.07a30.11 30.11 0 01-.46 4.27l-.05.21c-.01.03-.96 2.69.26 3.76l.12.06c1.7.89 6.11.63 12.4-.72l.41-.09.39.18c1.14.54 1.99 3.05 2.5 7.46a1.25 1.25 0 11-2.48.27 19.57 19.57 0 00-1.11-5.23c-8.9 1.84-11.83 1.08-13.27.33l-.24-.06-.11-.13c-2.26-1.68-1.87-4.89-1.26-6.62a23.88 23.88 0 00.38-5.05 15.35 15.35 0 00-1.41-4.62l-3.23-.66a3.07 3.07 0 01-2.48-1.36 3.29 3.29 0 01-.21-2.77l.08-.21 6.47-7.63c.64-1.02.67-3.35.53-4.6-2.05-13.45 7.29-18.34 8.29-18.81zm17.41 33.97a1.25 1.25 0 110 2.5h-2.94a1.25 1.25 0 010-2.5zm-1.53-23.82a9.7 9.7 0 019.53 9.82 8.55 8.55 0 01-1.78 5.74 14.24 14.24 0 01-1.81 1.65 6.18 6.18 0 00-2.58 3.34l-.24.94h-6.14l-.3-.83a7.75 7.75 0 00-2.71-3.48 16.23 16.23 0 01-1.67-1.58 9.1 9.1 0 01-1.85-5.79 9.69 9.69 0 019.55-9.81zm3.54 3.686a7.06 7.06 0 00-10.59 6.114 6.61 6.61 0 001.28 4.2c.428.46.886.89 1.37 1.29a10.77 10.77 0 013.09 3.71h.07v-6.53l-2.34-2.37a1.252 1.252 0 011.78-1.76l1.81 1.84 1.81-1.84a1.252 1.252 0 011.78 1.76l-2.34 2.37v6.53a9 9 0 013.11-3.68 12.7 12.7 0 001.47-1.32 6.08 6.08 0 001.23-4.2 7.06 7.06 0 00-3.53-6.114zM19.86 12.89c.45-.11.924.038 1.232.384l1.4 1.45a1.25 1.25 0 11-1.78 1.71l-1.39-1.4a1.25 1.25 0 01.538-2.144zm23.282.484a1.252 1.252 0 011.78 1.76l-1.39 1.4a1.252 1.252 0 01-1.78-1.76zm-11.04-5.56c.69 0 1.25.56 1.25 1.25v1.8a1.25 1.25 0 11-2.5 0v-1.8c0-.69.56-1.25 1.25-1.25z'
	]
};