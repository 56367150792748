export default {
	prefix: 'isd',
	iconName: 'niederlassungen',
	niceName: 'niederlassungen',
	icon: [
		71,
		61,
		[],
		'ff40',
		'M47.175 30.312a1.25 1.25 0 011.541-.684l11.71 5.31c.27.13.49.35.62.62l9.47 20.89-.01.01a1.25 1.25 0 01-1.65 1.65l-16.42-7.27-16.19 9.15-.17.07h-.13a1.19 1.19 0 01-.32 0l-.107.01a1.19 1.19 0 01-.213-.01h-.14l-.16-.07-16.2-9.16-16.42 7.27a1.24 1.24 0 01-1.4-.26 1.26 1.26 0 01-.25-1.39l9.47-20.89a1.27 1.27 0 01.63-.62l11.79-5.19a1.25 1.25 0 011 2.29l-11.34 5-7.92 17.46 13.52-6 4.13-10.92a1.25 1.25 0 012.33.89l-4 10.41 14 7.88v-7.94a1.25 1.25 0 012.5 0v7.94l14-7.88-4-10.41a1.25 1.25 0 012.33-.89l4.22 10.91 13.52 6-7.92-17.46-11.28-5.12a1.25 1.25 0 01-.54-1.596zM35.626.608c7.887 0 14.28 6.393 14.28 14.28 0 7.45-12.67 28.87-13.2 29.78a1.26 1.26 0 01-1.08.61 1.23 1.23 0 01-1.07-.61c-.54-.91-13.21-22.33-13.21-29.78 0-7.887 6.393-14.28 14.28-14.28zm0 2.49c-6.51.006-11.785 5.28-11.79 11.79 0 5.12 7.71 19.58 11.78 26.66 4.07-7.08 11.78-21.54 11.78-26.66-.006-6.501-5.269-11.773-11.77-11.79zm0 3.79a8 8 0 110 16 8 8 0 010-16zm3.896 4.122a5.49 5.49 0 00-9.376 3.878h-.02a5.49 5.49 0 005.5 5.49 5.49 5.49 0 003.896-9.368z'
	]
};