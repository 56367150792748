export default {
	prefix: 'isd',
	iconName: 'fuer-bewerber',
	niceName: 'fuer-bewerber',
	icon: [
		47,
		61,
		[],
		'ff18',
		'M41.29.575a5.26 5.26 0 015.25 5.25v49.78a5.26 5.26 0 01-5.25 5.25H5.95a5.26 5.26 0 01-5.25-5.25v-44.09a1.24 1.24 0 01.44-.94L12.3.885a1.25 1.25 0 01.82-.31zm0 2.5H16.17v11.89c0 .69-.56 1.25-1.25 1.25H7.49a1.25 1.25 0 010-2.5h6.18V3.085h-.08l-10.39 9v43.49a2.76 2.76 0 002.75 2.75h35.34a2.76 2.76 0 002.75-2.75V5.825a2.76 2.76 0 00-2.75-2.75zm-4.55 44.15a1.25 1.25 0 010 2.5H10.5a1.25 1.25 0 010-2.5zm0-8.51a1.25 1.25 0 010 2.5H10.5a1.25 1.25 0 010-2.5zm-5.58-17.79c4.28 0 6.84 2.12 6.81 5.66v5.84a1.25 1.25 0 01-2.5 0v-5.84c0-1.95-1.27-3.01-3.77-3.14l-3 3.59a1.24 1.24 0 01-.93.45 1.32 1.32 0 01-.95-.41l-3.29-3.63c-3.53.19-3.53 2.31-3.53 3.14v5.88a1.25 1.25 0 01-2.5 0v-5.84c0-3.54 2.45-5.66 6.57-5.66.35.002.684.15.92.41l2.72 3 2.5-3a1.27 1.27 0 01.95-.45zm-6.308-13.3a5.65 5.65 0 018.538 5.09v1.6a5.68 5.68 0 01-5.65 5.71 5.68 5.68 0 01-5.64-5.71v-1.6a5.65 5.65 0 012.752-5.09zM29.39 9.84a3.15 3.15 0 00-4.771 2.875v1.6a3.15 3.15 0 106.29 0v-1.6a3.15 3.15 0 00-1.519-2.875z'
	]
};